@import 'app.scss';

/* 图文板块 */
.image-text-box {
    .left,
    .right {
        height: 320px;
        overflow: hidden;
    }

    .right {
        padding: 50px 30px;
    }
}

/* 图文列表1 */
.image-text-list-1 {
    margin: 20px -15px 0;

    .item {
        display: block;
        width: 380px;
        padding-bottom: 40px;
        cursor: pointer;
        overflow: hidden;
        transition: all 0.3s;

        &.u-border-bottom::after {
            border-bottom-width: 2px;
            border-color: #e6e6eb;
        }

        .img-box {
            width: 380px;
            height: 220px;
        }

        h6 {
            color: $main-color;
        }

        p {
            color: $sub-color;
        }
    }

    .item:hover {
        h6 {
            color: $high-light;
        }

        &.u-border-bottom::after {
            border-color: $high-light;
        }
    }
}

/* 图文列表2 */
.image-text-list-2 {
    margin: 20px -15px 0;

    .item {
        display: block;
        height: 400px;
        padding: 30px;
        color: $main-color;
        background-color: $main-bg;
        cursor: pointer;
        overflow: hidden;
        transition: all 0.3s;

        &.u-border::after {
            border-color: #e1e1e6;
        }

        .img-box {
            width: 320px;
            height: 160px;
        }

        h6 {
            font-size: 18px;
        }

        p {
            color: $sub-color;
        }

        .icon {
            width: 30px;
            height: 30px;
            flex-shrink: 0;
        }

        .arrow-right {
            position: absolute;
            left: 30px;
            bottom: 30px;
        }
    }

    .item:hover {
        transform: scale(1.01);
    }
}

/* 图文列表3 */
.image-text-list-3 {
    background-color: $main-bg;
    height: 400px;

    .u-border-bottom::after {
        border-color: #e6e6eb;
    }

    .left {
        .name {
            font-size: 24px;
        }

        .content {
            width: 70%;
            color: $sub-color;
        }

        .more {
            position: absolute;
            left: 0;
            right: 0;
            color: $sub-color;
            bottom: 30px;

            & > i {
                margin-right: 80px;
            }
        }
    }

    .right {
        height: 340px;
        text-align: right;

        .img {
            width: 270px;
        }
    }
}

/* 图文列表4 */
.image-text-list-4 {
    margin: 25px -10px 0;

    .item {
        height: 180px;
        padding: 10px 20px;
        color: $main-color;
        background-color: $main-bg;
        cursor: pointer;
        transition: all 0.3s;

        .img {
            width: 150px;
        }

        h3 {
            font-size: 24px;
            font-weight: bold;
        }
    }

    .item:hover {
        transform: scale(1.01);
        box-shadow: 0 10px 5px -5px rgba(0, 0, 0, 0.05);
    }
}

/* 视频列表1 */
.media-list-1 {
    margin: 20px -15px 0;

    .item {
        display: block;
        width: 380px;
        color: $main-color;
        cursor: pointer;
        overflow: hidden;

        .img-box {
            height: 220px;
        }
    }

    i,
    .mask {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        transition: all 0.3s;
        display: none;
    }

    i {
        width: 70px;
        height: 70px;
        color: #fff;
        font-size: 70px;
    }

    .mask {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .item:hover {
        i,
        .mask {
            display: block;
        }
    }
}

/* 专题栏目 */
.zhuanti-list {
    margin: 20px -15px 0;

    .item {
        display: block;
        width: 380px;
        color: $main-color;
        cursor: pointer;
        overflow: hidden;

        .img-box {
            height: 220px;
        }
    }
}

/* 资讯列表1 */
.news-list-1 {
    li {
        height: 134px;
        cursor: pointer;
        transition: all 0.3s;

        &.u-border-bottom::after {
            border-bottom-width: 2px;
            border-color: #e6e6eb;
        }

        & > a {
            height: 100%;
            color: $sub-color;
        }

        .left {
            width: 70%;
            overflow: hidden;
        }

        h6 {
            font-weight: bold;
            color: $main-color;
        }

        .day {
            font-size: 36px;
        }
    }

    li:hover {
        position: relative;
        padding: 0 30px;
        background-color: $main-bg;
        border-radius: 2px;
        box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.05);

        &.u-border-bottom::after {
            border-bottom-width: 0;
        }
    }
}

/* 资讯列表2 */
.news-list-2 {
    li {
        height: 76px;
        padding: 0 30px;
        cursor: pointer;
        transition: all 0.3s;

        &.u-border-bottom::after {
            border-bottom-width: 2px;
            border-color: #e6e6eb;
        }

        h6 {
            width: 70%;
            overflow: hidden;
        }

        .right {
            text-align: right;
            flex-shrink: 0;
            color: $sub-color;
        }
    }

    li > a {
        height: 100%;
        color: $main-color;

        &::before {
            content: '';
            width: 8px;
            height: 8px;
            margin-right: 10px;
            background-color: $high-light;
            border-radius: 8px;
        }
    }

    li:hover {
        position: relative;
        background-color: $main-bg;
        border-radius: 2px;
        box-shadow: 0 10px 5px -5px rgba(0, 0, 0, 0.05);

        &.u-border-bottom::after {
            border-bottom-width: 0;
        }
    }
}

/* 时间线 */
.timeline-box {
    position: relative;

    .timeline-item {
        position: relative;
        cursor: pointer;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            top: 45px;
            left: 100px;
            background-color: $high-light;
            z-index: 0;
            width: 1px;
            height: 90%;
        }
    }

    .timeline-title {
        font-size: 24px;
        font-weight: bold;
        color: $high-light;
    }

    i {
        position: absolute;
        top: 14px;
        left: 95px;
        font-size: 12px;
        color: $high-light;
        cursor: pointer;
    }

    .timeline-content {
        margin-top: -30px;
        padding-left: 150px;

        ul {
            position: relative;
            top: -40px;
        }
    }

    .item {
        padding: 40px 0;
        transition: all 0.3s;

        a {
            color: $sub-color;
        }

        h3 {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 0;
            color: $main-color;
        }

        span {
            flex-shrink: 0;
            font-weight: bold;
        }
    }

    .item:hover {
        padding: 40px 30px;
        box-shadow: 0 15px 10px -5px rgba(0, 0, 0, 0.05);
    }
}

/* 首页 START*/
[class^='index_'] {
    .carousel-indicators {
        left: unset;
        right: 30px;
        margin-right: 0;
        margin-bottom: 15px;

        & > li {
            width: 30px;
            height: 4px;

            &.active {
                background-color: $high-light;
            }
        }
    }
}

// 首页轮播
#index_banner {
    .carousel-item {
        height: 480px;
        overflow: hidden;
    }

    .carousel-indicators {
        margin-bottom: 15px;

        & > li {
            width: 80px;
            height: 4px;

            &.active {
                background-color: $high-light;
            }
        }
    }
}

// 最新资讯
.index_latest-news {
    .container {
        height: 168px;
        text-align: center;
        background-color: $main-bg;
    }

    a {
        color: $main-color;
    }

    h2 {
        font-size: 30px;
        font-weight: bold;

        & > a {
            color: $high-light;
        }
    }

    .u-border-right::after {
        border-color: #e1e1e6;
    }
}

// 新闻板块
#index_news-box-carousel {
    .carousel-item {
        height: 460px;
        overflow: hidden;
    }
}

.index_news-box {
    user-select: none;

    .card {
        height: 460px;
        padding: 10px 30px;
        border: none;
        border-radius: 0;
        overflow: hidden;
    }

    .more {
        position: absolute;
        top: 10px;
        right: 20px;
        font-size: 14px;
        color: $main-color;
        cursor: pointer;
        z-index: 1;
    }

    .nav-tabs {
        border-bottom-color: #f0f0f5;

        .nav-link {
            color: $main-color;
            margin-bottom: 0;
            border: none;
        }

        .nav-link.active {
            font-weight: bold;
            color: $main-color;
            background-color: transparent;
            border-bottom: 2px solid $high-light;
        }
    }

    .tab-content {
        overflow: hidden;

        a {
            color: $main-color;
        }

        li > a::before {
            content: ' ';
            width: 8px;
            height: 8px;
            flex-shrink: 0;
            background-color: $high-light;
            border-radius: 2px;
        }

        span {
            color: $sub-color;
        }
    }
    .right-box{
        flex: 0 0 52.4%;
        max-width: 52.4%;
    }
    .left-box{
        flex: 0 0 47.6%;
        max-width: 47.6%;
    }
}

.index_notice {
    height: 102px;
    margin: 20px auto;
    padding-left: 30px;
    background-color: $main-bg;
    overflow: hidden;
    padding-top: 14px;
    padding-bottom: 14px;

    ul {
        height: 100%;
    }

    li {
        cursor: pointer;
    }

    a {
        color: $sub-color;
    }

    .custom-gutter{
        padding-right: 30px;
    }
    div.article-row {
        color: $main-color;

        label {
            font-weight: bold;
        }
    }
    .scroll-box{
        //height: ;
        height: 74px;
        overflow: hidden;
    }
}

.index_jsfw {
    width: 26px;
    height: 24px;
    background-image: url(../images/icon/index_jsfw.png);
}

// 主营业务
.index_zyyw {
    user-select: none;
    background-color: $main-bg;

    .left {
        display: none;
    }

    .left.show {
        display: block;
    }

    .item {
        display: flex;
        align-items: center;
        padding: 0 10px;
        height: 81px;
        font-size: 16px;
        margin-bottom: -1px;
        color: $main-color;
        cursor: pointer;
        border: 1px solid #e1e1e6;
        overflow: hidden;

        &.active {
            border: 1px solid $high-light;
            margin-bottom: 0;
        }

        & > span {
            flex: 1;
        }

        .icon {
            width: 50px;
            height: 50px;
            flex-shrink: 0;
        }

        .cover {
            display: none;
        }
    }

    .right {
        height: 406px;
        overflow: hidden;
    }

    .page {
        font-size: 16px;
        font-weight: bold;

        .current {
            color: $high-light;
        }

        i {
            cursor: pointer;
        }

        i + i {
            margin-left: 10px;
        }
    }
}

// 科技成果
#index_kjcg-carousel {
    .carousel-item {
        height: 400px;
        overflow: hidden;
    }
}

.index_kjcg {
    .card {
        border: none;
        border-radius: 0;
    }

    .left {
        padding: 20px 20px 0 20px;

        .item {
            color: $main-color;
            display: none;
        }

        .item.show {
            display: block;
        }

        h6 {
            font-weight: bold;
        }

        .time {
            color: $sub-color;
        }
    }

    .page {
        position: absolute;
        left: 30px;
        bottom: 30px;

        i {
            cursor: pointer;
        }

        i + i {
            margin-left: 20px;
        }
    }

    .bar {
        position: absolute;
        right: 0;
        bottom: 35px;
        width: 150px;
        height: 4px;
        margin-right: -75px;
        background-color: $high-light;
        z-index: 2;
    }
}

// 专题栏目
.index_ztlm {
    .row {
        margin: 0 -10px 0;
    }

    .item {
        display: block;
        height: 300px;
        transition: all 0.3s;
        cursor: pointer;
        overflow: hidden;

        .img {
            position: absolute;
            object-fit: cover;
        }

        .content {
            position: absolute;
            top: 60px;
            width: 100%;
            padding: 0 30px;
            color: #fff;
            z-index: 1;

            h6 {
                font-size: 24px;
                font-weight: bold;
            }

            i {
                display: none;
                position: absolute;
                margin-top: 60px;
                width: 41px;
                height: 11px;
                background-image: url(../images/icon/arrow-right-white.png);
            }
        }

        .mask {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: none;
            background-color: rgba(0, 92, 230, 0.8);
        }
    }

    .item:hover {
        i,
        .mask {
            display: block;
        }
    }
}

// 党建观澜
.index_djgl {

    .row {
        //padding: 10px;
        //padding: -10px;
        display: none;
        //background-color: #fff;
    }


    .row.show {
        display: flex;
        margin-right: -10px;
        margin-left: -10px;
        //margin-top: -10px;
    }

    .page {
        font-size: 16px;
        font-weight: bold;

        .current {
            color: $high-light;
        }

        i {
            cursor: pointer;
        }

        i + i {
            margin-left: 10px;
        }
    }

    .item {
        display: block;
        position: relative;
        height: 280px;
        padding: 0 10px;
        //margin-right: 20px;
        color: $sub-color;
        //background-color: $main-bg;
        cursor: pointer;
        transition: all 0.3s;

        .img-box {
            height: 183px;
            .img {
                object-fit: cover;
            }
        }

        h6 {
            color: $main-color;
            //font-weight: bold;
            min-height: 40px;
        }

    }


    button {
        width: 240px;
        height: 66px;
        font-size: 16px;
        color: $high-light;
        border-color: $high-light;
        border-radius: 0;

        &:hover {
            color: $high-light;
        }
    }
}

// 宣传片
.index_cxp,
.culture_xcp {
    background-color: $main-bg;

    .container {
        //padding: 30px 0 40px;
    }

    .left {
        //height: 560px;
        overflow: hidden;

        h3 {
            font-size: 24px;
            font-weight: bold;
        }

        a {
            color: $sub-color;
        }

        hr {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 40%;
            height: 5px;
            background-color: $high-light;
            border-radius: 2px;
        }
    }

    video {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
}

// 友情链接
.link-box {
    p {
        font-size: 24px;
        font-weight: bold;
    }

    li {
        position: relative;
        float: left;
        width: 298px;
        height: 98px;
        margin: 0 -1px -1px 0;
        cursor: pointer;
        border: 1px solid #ebebf0;

        a {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    li:hover {
        z-index: 1;
        border-color: $high-light;
    }
}

/* 首页 END */

/* 搜索 START */
.is-search {
    background-color: $main-bg;

    .form-control {
        width: 380px;
        padding-right: 90px;
        background-color: $body-bg;
        border: none;
        border-radius: 2px;

        &:focus {
            box-shadow: none;
        }
    }

    button {
        position: absolute;
        right: 0;
        top: 0;
        width: 80px;
        height: 100%;
        border-color: $high-light;
        background-color: $high-light;
        border-radius: 2px;

        &:hover {
            color: $high-light;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .search-icon {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        width: 18px;
        height: 18px;
        background-image: url(../images/icon/search.png);
        background-repeat: no-repeat;
        background-size: contain;
    }
}

/* 搜索 END */

/* 关于我们 START */
// 单位荣誉
.about-dwry {
    max-height: 1080px;
    background-color: $main-bg;
    overflow: hidden;

    .mask {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgb(255, 255, 255) 80%);
        margin-top: -150px;
        padding-top: 150px;
    }
}

// 组织架构
.about_zzjg {
    height: 440px;

    .img-box {
        max-height: 500px;
        overflow: hidden;
    }
}

.content-mode-2 {
    .more {
        color: $sub-color;
        border: none;
    }
}

.content-mode-3 {
    background-color: $main-bg;
}

/* 关于我们 END */

/* 新闻中心 START */
// 本院资讯
.news_tpxw,
.news_jwxw {
    background-color: $main-bg;
}

// 通知公告
.news_tzgg {
    height: 475px;
    overflow: hidden;
}

// 行业动态
.news_hydt {
    background-color: $main-bg;

    li:hover {
        box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.1);
    }
}

/* 新闻中心 END */

/* 主营业务 START */
.business_hxyw {
}

.business_dbgc {
}

/* 主营业务 END */

/* 科技创新 START */
.innovate_cxcg {
    .row {
        margin: 20px -15px 0;
    }
}

/* 科技创新 END */

/* 企业文化 START */
[class^='culture_'] {
    button {
        width: 306px;
        height: 50px;
        font-size: 16px;
        color: $high-light;
        border-color: $high-light;
        border-radius: 0;

        &:hover {
            color: $high-light;
        }
    }
}

// 企业品牌
.culture_qypp {
    height: 696px;
    padding: 123px 0;
    overflow: hidden;

    .container {
        height: 100%;
        background-color: $main-bg;
        z-index: 1;
    }

    .left {
        height: 450px;
        padding-right: 80px;

        .content {
            margin: 60px auto;
            font-size: 16px;
            color: $sub-color;
            white-space: pre-wrap;
        }

        .bar {
            position: absolute;
            right: 0;
            bottom: 100px;
            width: 150px;
            height: 4px;
            margin-right: -75px;
            background-color: $high-light;
            z-index: 1;
        }
    }

    .right {
        margin-top: -68px;

        .img {
            width: 500px;
            height: 586px;
        }
    }

    .white {
        position: absolute;
        top: 0;
        right: 100%;
        width: 100%;
        height: 100%;
        background-color: $main-bg;
        z-index: 0;
    }

    .gray {
        position: absolute;
        right: 0;
        top: 0;
        width: 56.25%;
        height: 406px;
        background-color: #ebebf0;
    }
}

// 企业精神
.culture_qyjs {
    padding: 80px 0;

    .container {
        z-index: 1;
    }

    .left {
        width: 684px;
        height: 460px;
        overflow: hidden;
    }

    .right {
        height: 460px;
        margin-left: 50px;

        .content {
            margin: 60px auto;
            font-size: 16px;
            color: $sub-color;
            white-space: pre-wrap;
        }

        a {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .white {
        position: absolute;
        right: 55%;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $main-bg;
    }
}

/* 企业文化 END */

/* 党群建设 START */
[class^='gov_'] {
    .block-title {
        .u-border-bottom::after {
            border-color: #e64539;
        }
    }

    li > a::before {
        background-color: #e64539;
    }

    .carousel-indicators {
        left: unset;
        right: 30px;
        margin-right: 0;
        margin-bottom: 15px;

        & > li {
            width: 30px;
            height: 4px;

            &.active {
                background-color: #e64539;
            }
        }
    }
}

// 党建观澜
#gov_djgl-carousel {
    .carousel-item {
        height: 385px;
        overflow: hidden;
    }
}

.gov_djgl {
    .card {
        height: 385px;
        padding: 10px 30px;
        border: none;
        border-radius: 0;
        overflow: hidden;

        p {
            color: $main-color;
        }

        li > a::before {
            content: ' ';
            flex-shrink: 0;
            width: 8px;
            height: 8px;
            border-radius: 2px;
        }
    }
}

// 党风廉政 | 团委组织 | 理论学习
.gov_dflz,
.gov_twzz,
.gov_llxx {
    height: 475px;
    background-color: $main-bg;
    overflow: hidden;
}

// 工会园地
.gov_ghyd {
    height: 475px;
    overflow: hidden;
}

// 专题栏目
.gov_ztlm {
    .container {
        padding: 40px 0 30px;
    }
}

.is_gov {
    li > a::before {
        background-color: #e64539;
    }

    .nav-link.u-border-bottom::after {
        border-bottom-color: #e64539;
    }

    .page-item.active {
        .page-link {
            background-color: #e64539;
            border-color: #e64539;
        }
    }
}

/* 党群建设 END */
.custom-gutter {
    padding-left: 5px;
    padding-right: 5px;
}
