/* 便捷类 */
.wrapper {
    width: 1200px;
    margin: 0 auto;
    padding: 0;
}

.img {
    width: 100%;
    height: 100%;
}

.is-circle {
    border-radius: 50%;
}

.u-rela {
    position: relative;
}

.u-abso {
    position: absolute;
}

.u-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.u-flex-col {
    display: flex;
    flex-direction: column;
}

.u-flex-wrap {
    flex-wrap: wrap;
}

.u-flex-nowrap {
    flex-wrap: nowrap;
}

.u-col-center {
    align-items: center;
}

.u-col-top {
    align-items: flex-start;
}

.u-col-bottom {
    align-items: flex-end;
}

.u-row-center {
    justify-content: center;
}

.u-row-left {
    justify-content: flex-start;
}

.u-row-right {
    justify-content: flex-end;
}

.u-row-between {
    justify-content: space-between;
}

.u-row-around {
    justify-content: space-around;
}

.u-text-left {
    text-align: left;
}

.u-text-center {
    text-align: center;
}

.u-text-right {
    text-align: right;
}

.u-ml-a {
    margin-left: auto;
}

.u-mr-a {
    margin-right: auto;
}

.u-mt-a {
    margin-top: auto;
}

.u-mb-a {
    margin-bottom: auto;
}

.u-fs-0 {
    flex-shrink: 0;
}

.u-line-1 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.u-line-2 {
    -webkit-line-clamp: 2;
}

.u-line-3 {
    -webkit-line-clamp: 3;
}

.u-line-4 {
    -webkit-line-clamp: 4;
}

.u-line-5 {
    -webkit-line-clamp: 5;
}
.u-line-8 {
    -webkit-line-clamp: 8;
}
.u-line-9 {
    -webkit-line-clamp: 9;
}
.u-line-10 {
    -webkit-line-clamp: 10;
}
.u-line-11 {
    -webkit-line-clamp: 11;
}
.u-line-11 {
    -webkit-line-clamp: 12;
}

.u-line-2,
.u-line-3,
.u-line-4,
.u-line-5,
.u-line-8,
.u-line-9,
.u-line-10,
.u-line-11,
.u-line-12{
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.u-border,
.u-border-bottom,
.u-border-left,
.u-border-right,
.u-border-top,
.u-border-top-bottom {
    position: relative;
}

.u-border-bottom:after,
.u-border-left:after,
.u-border-right:after,
.u-border-top-bottom:after,
.u-border-top:after,
.u-border:after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    box-sizing: border-box;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    width: 199.8%;
    height: 199.7%;
    transform: scale(0.5, 0.5);
    border: 0 solid $high-light;
    z-index: 2;
}

.u-border-top:after {
    border-top-width: 1px;
}

.u-border-left:after {
    border-left-width: 1px;
}

.u-border-right:after {
    border-right-width: 1px;
}

.u-border-bottom:after {
    border-bottom-width: 1px;
}

.u-border-top-bottom:after {
    border-width: 1px 0;
}

.u-border:after {
    border-width: 1px;
}

@for $i from 0 through 80 {
    @if $i % 2 == 0 or $i % 5 == 0 {
        .u-m-#{$i} {
            margin: $i + px;
        }
        .u-m-x-#{$i} {
            margin-left: $i + px;
            margin-right: $i + px;
        }
        .u-m-y-#{$i} {
            margin-top: $i + px;
            margin-bottom: $i + px;
        }

        .u-p-#{$i} {
            padding: $i + px;
        }
        .u-p-x-#{$i} {
            padding-left: $i + px;
            padding-right: $i + px;
        }
        .u-p-y-#{$i} {
            padding-top: $i + px;
            padding-bottom: $i + px;
        }

        @each $short, $long in l left, t top, r right, b bottom {
            // 定义外边距
            .u-m-#{$short}-#{$i} {
                margin-#{$long}: $i + px;
            }
            // 定义内边距
            .u-p-#{$short}-#{$i} {
                padding-#{$long}: $i + px;
            }
        }
    }
}
