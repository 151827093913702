// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import 'utils.scss';

body {
    min-width: 1200px;
}

h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
label,
ol,
ul,
dl {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
}

a {
    color: $sub-color;
}

a:hover {
    color: $sub-color;
    text-decoration: none;
}

button:focus {
    box-shadow: none;
}

.page-link {
    color: $sub-color;

    &:focus {
        box-shadow: none;
    }
}

.page-item.active {
    .page-link {
        background-color: $high-light;
        border-color: $high-light;
    }
}

@media (min-width: 1440px) {
    .container {
        max-width: 1200px;
    }
}

/* 导航栏 START */
.header-box {
    height: 90px;
    background-color: $main-bg;

    .wrapper {
        height: 100%;
    }

    .logo {
        width: 280px;
        height: 67px;
        overflow: hidden;
    }

    .nav {
        font-size: 16px;

        .nav-item {
            text-align: center;
            min-width: 70px;
            height: 90px;

            &:not(:first-child) {
                width: 130px;
            }

            &:first-child {
                .u-border-bottom::after {
                    border-bottom-width: 5px;
                }
            }
        }

        .dropdown:hover .dropdown-menu {
            display: block;
        }

        .dropdown-menu {
            top: 80px;
            width: 160px;
            text-align: center;
            font-size: 16px;
            border: none;
        }

        .dropdown-item {
            color: $main-color;

            &:hover {
                color: $high-light;
                background-color: $main-bg;
            }
        }

        .nav-link {
            height: 100%;
            line-height: 80px;
            color: $main-color;
        }

        .active {
            color: $high-light;
        }

        .is_gov > .active {
            color: #e64539;
        }
    }

    .search-box {
        background-color: $main-bg;
        height: 80px;

        .form-group {
            display: none;
            margin-bottom: 0;

            i {
                position: absolute;
                top: 0;
                bottom: 0;
                width: 26px;
                height: 26px;
                font-size: 26px;
                margin: auto;
            }
        }

        .form-control {
            width: 500px;
            background-color: $body-bg;
            border: none;

            &:focus {
                box-shadow: none;
            }
        }

        .search-icon {
            width: 26px;
            height: 26px;
            background-image: url(../images/icon/search.png);
        }

        i {
            cursor: pointer;
        }
    }
}

/* 导航栏 END */

/* 底部 START */
.footer-box {
    height: 220px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: $high-light;

    a {
        color: #fff;
    }

    a:hover {
        text-decoration: none;
    }

    hr {
        margin: 25px auto;
        border-bottom: 1px solid #186ae5 !important;
    }

    .footer-link a {
        margin-right: 40px;
    }

    .footer-contact > span::after,
    .icp > span::after {
        content: '|';
        margin: 0 20px;
    }

    .icp {
        img {
            vertical-align: text-bottom;
        }
    }

    .sydw {
        width: 80px;
        align-self: flex-end;
    }

    .qrcode {
        width: 120px;
    }
}

/* 底部 END */

.arrow-right {
    width: 41px;
    height: 11px;
    cursor: pointer;
    background-image: url(../images/icon/arrow-right.png);
}

.banner {
    height: 300px;
    overflow: hidden;
}

.no-result {
    font-size: 24px;
    font-weight: bold;
}

/* 区块标题 START */
.block-title {
    display: flex;
    align-items: center;

    h3 {
        margin-bottom: 0;
        margin-right: 10px;
        font-size: 24px;
        font-weight: bold;
    }

    span,
    a {
        color: $sub-color;
    }

    .u-border-bottom::after {
        top: 10px;
        width: 100%;
        border-bottom-width: 8px;
    }
}

/* 区块标题 END */

/* 内容页头部导航和面包屑 START */
.content-nav {
    height: 90px;

    .container {
        height: 100%;
    }

    .nav {
        .nav-link {
            color: $sub-color;
        }

        .nav-link.u-border-bottom {
            color: $main-color;
            font-weight: bold;
        }

        .u-border-bottom::after {
            border-bottom-width: 4px;
        }
    }

    .breadcrumb {
        margin-bottom: 0;
        padding: 0;
        background-color: transparent;

        a {
            color: $sub-color;
        }

        .active {
            color: $main-color;
        }

        li:first-child {
            color: $sub-color;
        }
    }
}

/* 内容页头部导航和面包屑 END */

.detail {
    padding: 50px;
    background-color: $main-bg;

    h1 {
        font-size: 24px;
        font-weight: bold;
    }

    .title {
        line-height: 1.6;
    }

    .time {
        color: $sub-color;
    }

    .video-box {
        height: 620px;

        video {
            width: 100%;
            height: 100%;
        }
    }

    p {
        line-height: 2;
    }
}

#historyBack {
    position: fixed;
    bottom: 100px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-image: url(../images/icon/back.png);
    background-repeat: no-repeat;
}

#scrollUp {
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-image: url(../images/icon/TOP.png);
    background-repeat: no-repeat;
}

/* 滚动条美化 */
//::-webkit-scrollbar {
//  width: 5px;
//  height: 5px;
//  background-color: #f5f5f5;
//}
//::-webkit-scrollbar-track {
//  border-radius: 10px;
//  background-color: #f5f5f5;
//}
//::-webkit-scrollbar-thumb {
//  border-radius: 10px;
//  background-color: #c8c8c8;
//}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.font-18{
    font-size: 18px;
}
