// Body
$body-bg: #f5f5fa;

// Typography
$font-family-sans-serif: 'Microsoft Yahei', sans-serif;

$grid-gutter-width: 0;

$breadcrumb-divider: quote('>');

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$high-light: #005ce6;
$main-color: #3e4a5c;
$sub-color: #969699;
$main-bg: #fff;
